import {
  AGENT,
  AGENT_COLLECTION,
  AGENT_GROUP,
  AGENT_INCENTIVES,
  AGENT_MANAGEMENT,
  AGENTS_PERFORMANCE,
  AUTO_INVENTORY_REPLENISHMENT,
  AUTOMATIC_PARTIAL_FULFILLMENT,
  AUTOMATIC_PROCUREMENT_CONFIG,
  BIDDING_INVITATION_LIST,
  BIDDING_REQUEST,
  BRAND_PORTAL,
  BUY_IN_INVOICE,
  CATEGORY_INSIGHTS,
  CLAIMS_MANAGEMENT,
  CONFIG_CM3_TARGET,
  CONSIGNMENT_CASH_RECONCILIATION,
  CONSOLIDATED_PURCHASE_REQUEST,
  COVERAGE_REPORT,
  DELIVERY_MANAGEMENT,
  DELIVERY_PERFORMANCE,
  DEPOSIT_CONFIG,
  DISTRIBUTOR_CENTER,
  DISTRIBUTOR_CONTRACT_FEE,
  DISTRIBUTOR_LIST,
  DISTRIBUTOR_MANAGEMENT,
  DISTRIBUTOR_MARGIN,
  DISTRIBUTOR_PROFILE,
  DISTRIBUTOR_PURCHASE_ORDER,
  DISTRIBUTOR_PURCHASE_REQUEST,
  DISTRIBUTOR_REGISTER,
  FAILED_DELIVERY_RECEIVE,
  FINANCE,
  FINANCE_TASK_LIST,
  FULFILLMENTS,
  GOODS_NOT_FOCUS,
  HOME,
  INBOUND_LIST,
  INCENTIVES_CONFIG,
  INVENTORY_ALLOCATION,
  INVENTORY_DISTRIBUTOR_AGENT,
  INVENTORY_LIST,
  INVENTORY_RECOMMEND,
  INVENTORY_REPORT,
  LOGISTICS,
  MARKETING,
  MARKETING_BUDGET,
  MASTER_DATA,
  MENU_PRODUCT,
  MISSION_PERFORMANCE,
  NOTIFICATION,
  ORDER_LIST,
  OUTLETS_PERFORMANCE,
  PACKAGE_LIST,
  PARTNER,
  PAYMENT_REQUEST,
  PAYOUT_STATEMENT,
  PICKLIST_ASSIGNMENT,
  PRE_ORDERS,
  PRICE_MANAGEMENT,
  PRICE_TYPE_CONFIG,
  PROCUREMENT,
  PROCUREMENT_CONFIG,
  PRODUCT_AVAILABLE,
  PRODUCT_LIST,
  PRODUCT_LIST_VN,
  PRODUCT_LISTING,
  PRODUCT_PRE_ORDER,
  PRODUCT_PRE_ORDER_INDIA,
  PRODUCT_TRADING_BY_LISTING,
  PURCHASE_ORDER_LIST,
  PURCHASING,
  REASON_CANCEL,
  REASON_NEED_ADJUST,
  REMITTANCE_LIST,
  REPORT,
  REPORT_DISTRIBUTOR_RECONCILIATION,
  REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
  REPORT_RIDER,
  RIDER,
  RIDER_MANAGEMENT,
  SALES_PERFORMANCE,
  SELL_OUT_INVOICE,
  SETTINGS,
  STOCK_TRANSFER,
  SUBSIDY_RULE,
  SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
  SUPPLIER_LIST,
  SUPPLIER_LIST_VN,
  SUPPLIER_PORTAL,
  SUPPLIER_PRODUCT_LIST,
  SUPPLIER_PRODUCT_LIST_VN,
  TASK_LIST,
  TAX_MANAGEMENT,
  TOTAL_INVENTORY_REPORT,
  TOTAL_PURCHASE_VALUE_REPORT,
  TRADE_ORDERS,
  TRANSPORTATION_VENDOR,
  USER_LIST,
  VEHICLE_MANAGEMENT,
  VIGO_DELIVERY,
  VIGO_MASTER_QUOTATION,
  VIGO_PURCHASE_ORDER,
  WAREHOUSE_LIST,
  WAREHOUSE_WMS_LIST,
  WMS,
} from 'common/config/routes'
import {
  AGENT_COLLECTION_TRANSLATE,
  AGENT_TRANSLATE,
  BIDDING_INVITATION_KEY,
  BIDDING_REQUEST_KEY,
  BUDGET,
  BUY_IN_INVOICE_KEY,
  COMMON,
  CONSIGNMENT_CASH,
  CONSOLIDATED_PR,
  CONTRACT_FEE,
  DELIVERY,
  DEPOSIT,
  DISTRIBUTOR_PROFILE_MANAGEMENT,
  DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB,
  INBOUND,
  INVENTORY,
  ORDERS,
  PACKAGE,
  PAYMENT_REQUEST_BREADCRUMB,
  PICKLIST,
  PO,
  PO_REPORT,
  PRE_ORDERS_TRANSLATE,
  PRODUCT,
  PURCHASE_REQUEST,
  REMITTANCE,
  RIDER_REPORT,
  RIDERS,
  SA_PAYOUT_STATEMENT_DETAIl,
  SELL_OUT_INVOICE_KEY,
  SIGN_UP,
  SUBMIT_BIDDING,
  SUBSIDY,
  TRADE_ORDERS_TRANSLATE,
  VEHICLE,
  VIGO_MASTER,
  VIGO_PO,
  VIGO_QUOTATION,
  WAREHOUSE,
} from 'utils/localUtils'
import {
  WORKING_DAY_FRIDAY,
  WORKING_DAY_MONDAY,
  WORKING_DAY_SATURDAY,
  WORKING_DAY_SUNDAY,
  WORKING_DAY_THURSDAY,
  WORKING_DAY_TUESDAY,
  WORKING_DAY_WEDNESDAY,
} from 'utils/supplier'

import { AGENT_VI } from '../vi/agent/agent-management'
import { AGENT_COLLECTION_VI } from './agent/agent-collection'
import { CONSIGNMENT_CASH_VI } from './consignmentCash'
import { PURCHASING_VI } from './distributor-center/purchasing'
import { DISTRIBUTOR_PURCHASE_ORDER_VI } from './distributor-center/purchasing/distributor-purchase-order'
import { PAYMENT_REQUEST_VI } from './distributor-center/purchasing/payment-request'
import { PURCHASE_REQUEST_VI } from './distributor-center/purchasing/purchase-request'
import { VIGO_QUOTATION_VI } from './distributor-center/purchasing/vigo-quotation'
import { DISTRIBUTOR_PROFILE_VI } from './distributor-profile'
import { BUY_IN_INVOICE_VI } from './finance/buy-in-invoice'
import { DISTRIBUTOR_CONTRACT_FEE_VI } from './finance/distributor-contract-fee'
import { SELL_OUT_INVOICE_VI } from './finance/sell-out-invoice'
import { ORDERS_VI } from './fulfillment/orders'
import { PICKLIST_VI } from './fulfillment/picklist'
import { PRE_ORDERS_VI } from './fulfillment/pre-orders'
import { TRADE_ORDERS_VI } from './fulfillment/tradeOrder'
import { HOME_VI } from './home'
import { BUDGET_VI } from './marketing/budget'
import { SUBSIDY_VI } from './marketing/subsidy'
import { PRODUCT_VI } from './master-data/product-available'
import { PRODUCT_LISTINGS_VI } from './master-data/product-listings'
import { PRODUCT_PRE_ORDER_VI } from './master-data/product-pre-order'
import { PRODUCT_TRADING_BY_LISTING_VI } from './master-data/product-trading-by-listings'
import { REASON_NEED_ADJUST_VI } from './master-data/reasonNeedAdjust'
import { AUTOMATIC_CONFIG_VI } from './procurement/automatic-procurement-config'
import { CONSOLIDATED_PR_VI } from './procurement/consolidatedPR'
import { DEPOSIT_CONFIG_VI } from './procurement/deposit-config'
import { VIGO_MASTER_QUOTATION_VI } from './procurement/vigo-master-quotation'
import { VIGO_PURCHASE_ORDER_VI } from './procurement/vigo-purchase-order'
import { REPORT_RIDER_VI } from './report/rider'
import { DELIVERY_VI } from './rider/delivery'
import { PACKAGE_VI } from './rider/package'
import { REMITTANCE_VI } from './rider/remittance'
import { RIDER_VI } from './rider/rider'
import { VEHICLE_VI } from './rider/vehicle'
import { TASK_VI } from './settings/tasks'
import { SIGN_UP_VI } from './sign-up'
import { BIDDING_INVITATION_VI } from './supplier-portal/bidding-invitations'
import { BIDDING_REQUEST_VI } from './supplier-portal/bidding-request'
import { PO_VI } from './supplier-portal/purchase-order'
import { PURCHASE_ORDER_REPORT_VI } from './supplier-portal/purchase-order-report'
import { AUTO_INVENTORY_REPLENISHMENT_VI } from './wms/auto-inventory-replenishment'
import { INBOUND_VI } from './wms/inbound'
import { INVENTORY_VI } from './wms/inventory'
import { STOCK_TRANSFER_VI } from './wms/stock-transfer'
import { WAREHOUSE_VI } from './wms/warehouse'

const common = {
  email: 'Email',
  password: 'Mật khẩu',
  login: 'Đăng nhập',
  loginGoogle: 'Đăng nhập với Google',
  or: 'Hoặc',
  actions: 'Thao tác',
  warehouseCode: 'Mã kho hàng',
  emailRequired: 'Email là bắt buộc!',
  passwordRequired: 'Mật khẩu là bắt buộc',
  logout: 'Đăng xuất',
  changePassword: 'Đổi mật khẩu',
  prev: 'Trang trước',
  next: 'Trang kế tiếp',
  records: 'dòng',
  page: 'Trang',
  validateEmail: 'Vui lòng nhập đúng định dạng email!',
  statusHistory: 'Lịch sử thay đổi trạng thái',
  no: 'Thứ tự',
  status: 'Trạng thái',
  createdBy: 'Người tạo',
  createdDate: 'Ngày tạo',
  updatedBy: 'Người cập nhật',
  updatedDate: 'Ngày cập nhật',
  messageErrorPassword: 'Email hoặc mật khẩu không đúng, vui lòng thử lại',
  hours: 'Giờ',
  action: 'Thao tác',
  search: 'Tìm kiếm',
  refresh: 'Làm mới ',
  reset: 'Cài lại',
  update: 'Cập nhật',
  cancel: 'Hủy',
  save: 'Lưu',
  ok: 'Đồng ý',
  delete: 'Xóa',
  deleteConfirm: 'Bạn có chắc chắn muốn xóa?',
  locale: 'vi',
  '/': 'Trang chủ',
  detail: 'Chi tiết',
  create: 'Tạo mới',
  edit: 'Chỉnh sửa',
  create_retail_po: 'Create Retail Purchase Order',
  createNew: 'Tạo mới',
  poNumber: 'Mã đơn đặt hàng',
  inboundNumber: 'Mã đơn nhập hàng',
  invoiceNumber: 'Mã hóa đơn',
  distributor: 'Nhà phân phối',
  distributorCode: 'Mã nhà phân phối',
  selectDistributor: 'Chọn nhà phân phối',
  warehouse: 'Kho hàng',
  selectWarehouse: 'Chọn kho hàng',
  supplier: 'Nhà cung cấp',
  selectSupplier: 'Chọn nhà cung cấp',
  selectStatus: 'Chọn trạng thái',
  startDate: 'Ngày bắt đầu',
  endDate: 'Ngày kết thúc',
  approvedDate: 'Ngày duyệt',
  approvedBy: 'Người duyệt',
  uploadFile: 'Tải tập tin lên',
  fileSizeMsg: 'Kích thước tệp phải nhỏ hơn {size} MB',
  uploading: 'Đang tải lên',
  startUpload: 'Bắt đầu tải lên',
  chooseFile: 'Chọn tập tin',
  downloadFile: 'Tải tập tin xuống',
  invoiceDate: 'Ngày xuất hóa đơn',
  extraInformation: 'Thông tin bổ sung',
  reasonCancel: 'Lý do hủy',
  createReasonCancel: 'Tạo mới lý do hủy',
  createProductSuccessTitle: 'Cảm ơn bạn đã gửi yêu cầu này!',
  createProductSuccessSub: 'Hệ thống sẽ thông báo cho bạn khi có kết quả.',
  viewRequestedProduct: 'Xem sản phẩm đã yêu cầu',
  selectPackageUnit: 'Vui lòng chọn một đơn vị',
  selectCategories: 'Vui lòng chọn một hoặc nhiều danh mục',
  ex: 'VD',
  rejectReason: 'Lý do từ chối',
  suggest: 'Gợi ý chỉnh sửa',

  uploadMessage: {
    success: 'Tải lên tập tin thành công!',
    error: 'Tải lên tập tin không thành công!',
  },
  downloadMessage: {
    error: 'Tải xuống tập tin không thành công!',
  },
  updateMessage: {
    success: 'Cập nhật {name} thành công!',
    error: 'Cập nhật {name} không thành công!',
  },
  deleteMessage: {
    success: 'Xóa {name} thành công!',
    error: 'Xóa {name} không thành công!',
  },
  createMessage: {
    success: 'Tạo {name} thành công!',
    error: 'Tạo {name} không thành công!',
  },
  cancelMessage: {
    success: 'Hủy {name} thành công!',
    error: 'Hủy {name} không thành công!',
  },
  getMessage: {
    success: 'Lấy dữ liệu {name} thành công!',
    error: 'Lấy dữ liệu {name} không thành công!',
  },
  exportMessage: {
    success: 'Xuất dữ liệu thành công!',
    detail: 'Nhấn vào đây để tải dữ liệu',
    error: 'Xuất dữ liệu không thành công!',
  },
  actionMessage: {
    success: '{name} thành công!',
    error: '{name} không thành công!',
  },
  searchOrEnterName: 'Chọn hoặc nhập tên để tìm kiếm',
  required: '{name} là bắt buộc!',
  requiredInput: 'Vui lòng nhập {name}',
  requiredUpload: 'Vui lòng tải {name} lên!',
  requiredSelect: 'Vui lòng chọn {name}!',
  errorMessage: 'Có lỗi đã xảy ra!',
  selectDate: 'Chọn ngày',
  shippingFee: 'Phí giao hàng',
  poPrice: 'Giá trên đơn mua hàng',
  productName: 'Tên sản phẩm',
  product: 'Sản phẩm',
  searchProduct: 'Tìm kiếm sản phẩm',
  quantity: 'Số lượng',
  nationalDrugCode: 'Mã dược quốc gia',
  batchNumber: 'Số lô',
  shelfLifeMonth: 'Thời hạn sử dụng (Tháng)',
  manufacturingDate: 'Ngày sản xuất',
  expirationDate: 'Ngày hết hạn',
  acceptedQty: 'Số lượng thực nhận',
  rejectedQty: 'Số lượng từ chối',
  rejectedReason: 'Lý do từ chối',
  packageType: 'Đơn vị đóng gói',
  actualPrice: 'Giá thực tế',
  vatPrice: 'Giá sau thuế',
  totalAmount: 'Tổng giá trị',
  vatTotalAmount: 'Tổng giá trị sau thuế',
  priceDiff: 'Giá chênh lệch',
  total: 'Tổng cộng',
  totalQty: 'Tổng số lượng',
  submit: 'Cập nhật',
  batchExistMessage: 'Số lô đã tồn tại!',
  error: 'Lỗi',
  exportType: {
    csv: 'Xuất dữ liệu ra định dạng CSV',
    xlsx: 'Xuất dữ liệu ra định dạng Excel',
    xlsx_complete: 'Xuất dữ liệu ra định dạng Excel và hoàn thành',
  },
  brand: 'Thương hiệu',
  category: 'Danh mục',
  brandGroup: 'Nhóm thương hiệu',
  orderNumber: 'Mã đơn giao hàng',
  tradeOrderNumber: 'Mã đơn hàng',
  image: 'Hình ảnh',
  recordsFound: 'dòng dữ liệu được tìm thấy',
  district: 'Quận',
  export: 'Xuất dữ liệu',
  receiver: 'Người nhận',
  sender: 'Người gửi',
  addNew: 'Thêm mới',

  outlet: 'Cửa hàng',
  outletName: 'Tên cửa hàng',
  agentName: 'Tên nhân viên bán hàng',
  package: 'Kiện hàng',
  name: 'Họ tên',
  phone: 'Số điện thoại',
  address: 'Địa chỉ',
  latitude: 'Vĩ độ',
  longitude: 'Kinh độ',
  tags: 'Thẻ',
  limitDateMsg:
    'Khoảng cách từ Ngày bắt đầu đến Ngày kết thúc không được lớn hơn {day} ngày!',
  confirm: 'Xác nhận',
  reject: 'Từ chối',
  unknown: 'Không xác định',
  undefined: '-',
  null: '-',
  invalid: 'Không hợp lệ',
  reason: 'Lý do',
  otherReason: 'Lý do khác',
  moreFilter: 'Thêm bộ lọc',
  distributorValidMsg: 'Email: {email} không phải là nhà phân phối!',
  view: 'Xem chi tiết',
  download: 'Tải về',
  noData: 'Không có dữ liệu',
  addNewStock: 'Thêm tồn mới',
  updateStockHistory: 'Lịch sử cập nhật tồn kho',
  updateStock: 'Cập nhật tồn',
  stock: 'Tồn kho',
  noOfCase: 'Số lượng thùng',
  noOfPiece: 'Số lượng lẻ',
  oldStock: 'Tồn cũ(Thùng/Lẻ)',
  newStock: 'Tồn mới(Thùng/Lẻ)',
  requiredQuantity: 'Vui lòng nhập số lượng!',
  currentStock: 'Tồn hiện tại(Thùng/Lẻ)',
  packSize: 'Quy cách đóng gói',
  validateNoOfPiece: 'Số lượng lẻ phải nhỏ hơn quy cách đóng gói',
  updateSuccessful: 'Cập nhật thành công',
  note: 'Ghi chú',
  selectProvince: 'Chọn tỉnh',
  processType: 'Loại quy trình',
  trading: 'Quy trình 1-Trading',
  retail: 'Quy trình 2-Retail',
  trading_by_listing: 'Quy trình 3-Trading By Listing',
  consignment: 'Quy trình ký gửi',
  selectProcessType: 'Chọn loại quy trình',
  upload: 'Tải lên',
  uploadSuccess: 'đã được tải lên thành công',
  errorFileSize: 'Tập tin phải nhỏ hơn 10MB!',
  messageConfirmChangePassword:
    'Chú ý: Phiên làm việc của tất cả các thiết bị sẽ bị buộc đăng nhập lại',
  currentPassword: 'Mật khẩu hiện tại',
  newPassword: 'Mật khẩu mới',
  newPasswordConfirm: 'Xác nhận mật khẩu mới',
  requireCurrentPassword: 'Vui lòng nhập mật khẩu hiện tại!',
  requireNewPassword: 'Vui lòng nhập mật khẩu mới!',
  requireNewPasswordConfirm: 'Vui lòng xác nhận mật khẩu mới!',
  passwordNotMatch:
    'Mật khẩu mới và mật khẩu xác nhận không trùng khớp, vui lòng kiểm tra lại!',
  messageErrorOldPassword: 'Mật khẩu hiện tại không đúng!',
  passwordUpdated: 'Cập nhật mật khẩu thành công!',
  updateFailed: 'Cập nhật mật khẩu thất bại!',
  createFailed: 'Tạo thành công',
  apply: 'Tìm kiếm',
  productSku: 'Sản phẩm - SKU',
  updatedCreatedDate: 'Ngày cập nhật / Ngày tạo',
  on: 'BẬT',
  off: 'TẮT',
  sku: 'Mã SKU',
  activeStatus: 'Trạng thái',
  updateHistory: 'Lịch sử thay đổi',
  isLast7Days: '7 ngày trước',
  isLast30Days: '30 ngày trước',
  unit: 'Đơn vị bán hàng',
  active: 'Hoạt động',
  inActive: 'Không hoạt động',
  all: 'Tất cả',
  fulfillmentOrderNumber: 'Mã đơn giao hàng',
  tradeOrderCreatedDate: 'Ngày tạo đơn hàng',
  fulfillmentOrderCreatedDate: 'Ngày tạo đơn giao hàng',
  orderType: 'Loại đơn hàng',
  generalInformation: 'Thông tin chung',
  updatedDateLabel: 'Ngày cập nhật',
  tradeOrderManagement: 'Quản Lý Đơn Hàng',
  preOrderManagement: 'Quản lý đơn đặt trước',
  tradeOrder: 'Đơn hàng',
  items: 'Sản phẩm',
  shippingType: 'Phương thức thanh toán',
  allStatus: 'Tất cả trạng thái',
  newPaginationGoTo: 'Đến trang',
  totalItems: 'Tổng {items} mục',
  copyData: 'Sao chép dữ liệu',
  nameProduct: 'Tên sản phẩm',
  minimumOrderQuantity: 'Số lượng đặt hàng tối thiểu',
  referralPrice: 'Giá chào hàng',
  importData: 'Tải tập tin lên hệ thống',
  exportData: 'Tải dữ liệu xuống',
  selectCategory: 'Danh mục',
  clear: 'Cài lại',
  warehouseManagement: 'Quản lý kho hàng',
  code: 'Mã kho hàng',
  nameWarehouse: 'Tên kho hàng',
  fssai: 'Số FSSAI',
  isPickupLocation: 'Là địa chỉ lấy hàng',
  isWholesaleHub: 'Là kho sỉ trung tâm',
  wholesaleOwner: 'Chủ sở hữu kho sỉ',
  vigo: 'Vigo',
  contactName: 'Tên liên hệ',
  contactPhone: 'Số điện thoại liên hệ',
  contactEmail: 'Địa chỉ email liên hệ',
  totalPricePO: 'Tổng giá trị đặt hàng trên ngày',
  createdAt: 'Ngày tạo',
  createNewWarehouse: 'Tạo kho hàng mới',
  updateWarehouse: 'Cập nhật kho hàng',
  contactInformation: 'Thông tin liên hệ',
  addressInformation: 'Thông tin địa chỉ',
  regionSupport: 'Khu vực hỗ trợ giao hàng',
  postCode: 'Mã bưu điện',
  coordinate: 'Định vị',
  street: 'Đường',
  editWarehouse: 'Chỉnh sửa kho hàng',
  deleteWarehouse: 'Xóa kho hàng',
  validateMsg: {
    less: '{name} phải nhỏ hơn {qty}',
    greater: '{name} phải lớn hơn {qty}',
    lessOrEqual: '{name} phải nhỏ hơn hoặc bằng {qty}',
    greaterOrEqual: '{name} phải lớn hơn hoặc bằng {qty}',
  },

  subsidyBudget: 'Ngân sách trợ giá',
  totalSubsidyBudget: 'Tổng ngân sách trợ giá',
  unitBudget: 'đơn vị',
  budgetManagement: 'Quản lý ngân sách',

  budgetName: 'Tên ngân sách',
  budgetAmount: 'Ngân sách dự toán',
  sourceIncome: 'Nguồn thu',
  promotionTypeSentOn: 'Loại khuyến mãi đã dùng',
  distributorName: 'Nhà phân phối',
  ownerEmail: 'Email chủ sở hữu',
  ownerPhone: 'Số điện thoại chủ sở hữu',
  accountantEmail: 'Email kế toán',
  ownerName: 'Tên chủ sở hữu',
  taxCode: 'Mã số thuế',
  bankInfo: 'Thông tin tài khoản ngân hàng',
  bankBusiness: 'Thông tin thanh toán doanh nghiệp',
  bankCollection: 'Thông tin tài khoản nhận thanh toán từ khách hàng',
  bank: 'Ngân hàng',
  accountName: 'Chủ tài khoản',
  accountNumber: 'STK',
  billingAddress: 'Địa chỉ thanh toán',
  shippingAddress: 'Địa chỉ giao hàng',
  createFrom: 'Ngày tạo',
  createTo: 'Đến',
  saCommission: 'Hoa hồng nhân viên bán hàng',
  saCommissionUnit: 'Đơn vị hoa hồng',
  spentAmount: 'Ngân sách đã dùng',
  createNewTitle: 'Tạo mới { name }',
  profileInfo: 'Thông tin',
  type: 'Phân loại',
  placeholderSelect: 'Chọn {name}',
  industry: 'Ngành hàng',
  groupEmail: 'Email nhóm',
  fmcg: 'Tiêu dùng nhanh',
  pharmacy: 'Dược',
  otherInfo: 'Thông tin khác',
  licenseInfo: 'Thông tin giấy phép',
  businessRegistration: 'Giấy phép đăng ký kinh doanh',
  licenseQualifyPharmacy: 'Giấy phép đủ điều kiện kinh doanh dược',
  licenseStoreGDP: 'Giấy phép kho GDP',
  bankName: 'Tên ngân hàng',
  bankNumber: 'Số tài khoản ngân hàng',
  bankAccountName: 'Tên chủ tài khoản ngân hàng',
  branchCode: 'Mã chi nhánh',
  addressDetail: 'Địa chỉ cụ thể',
  profile: 'Hồ sơ',
  updatedInformation: 'Thông tin đã cập nhật',
  previousInformation: 'Thông tin trước đó',
  needUpdate: 'Cần cập nhật',
  approvalDate: 'Ngày được chấp thuận',
  updateInformation: 'Thông tin cập nhật',
  viewHistory: 'Xem lịch sử',
  spentID: 'Mã ngân sách đã dùng',
  sourceID: 'Mã ngân sách dự toán',
  entityNumber: 'Mã ngân sách',
  inputToSearch: 'Nhập {name} để tìm kiếm',
  historyChange: 'Lịch sử thay đổi',
  invoice: 'Hóa đơn',
  invoice_updated_yes: 'Có',
  invoice_updated_no: 'Không',
  invoiceUpdated: 'Đã cập nhật hóa đơn',
  invoiceInformation: 'Thông tin hóa đơn điện tử',
  invoiceUrl: 'Đường dẫn hóa đơn điện tử',
  invoiceQRCode: 'Mã QR hóa đơn điện tử',
  printInvoice: 'In hóa đơn điện tử',
  viewReason: 'Xem lý do',
  leadTime: 'Thời gian',
  priceTypeConfig: 'Loại giá tính hoa hồng Vigo',
  priceType: 'Loại giá',
  price: 'Giá bán',
  price_vat: 'Giá bán (VAT)',
  purchase_price: 'Giá mua vào',
  purchase_price_vat: 'Giá mua vào (VAT)',
  addBatch: 'Thêm số lô',
  receiverName: 'Tên người nhận',
  receiverCode: 'Mã người nhận',
  receiverPhone: 'Số điện thoại người nhận',
  pre_order_yes: 'Có',
  pre_order_no: 'Không',
  preOrder: 'Đặt hàng trước',
  tradeOrderNumbers: 'Mã đơn hàng',
  add: 'Thêm sản phẩm',
  fileExcelError: 'Vui lòng kiểm tra lại tài liệu đã tải lên!',
  vat: 'thuế VAT',
  taxManagement: 'Quản lý thuế VAT',
  supplierInvoiceProductMapping: 'Liên kết hóa đơn nhà cung cấp',
  conversionRate: 'Tỷ lệ chuyển đổi',
  supplierProductName: 'Tên sản phẩm nhà cung cấp',
  supplierUnit: 'Đơn vị nhà cung cấp',
  goodsNotFocus: 'Hàng không tập trung',
  condition: 'Điều kiện',
  value: 'Giá trị',
  convertToRetail: 'Chuyển sang Qui Trình 2 - Retail',
  notificationConvertToRetail: {
    success: 'Chuyển sang Qui Trình 2 - Retail thành công!',
    failed: 'Chuyển sang Qui Trình 2 - Retail thất bại!',
  },
  ratioSalable: 'Tỷ trọng tồn kho',
  ratioAmount: 'Tỷ trọng giá trị tồn kho',
  ratioNumber: 'Tỷ trọng mặt hàng tồn kho',
  messageRatio:
    'Tỷ trọng tồn kho + Tỷ trọng giá trị tồn kho + Tỷ trọng mặt hàng tồn kho SKU = 100',
  paymentStatus: 'Trạng thái thanh toán',
  finalBatchNumber: 'Số lô trên hóa đơn',

  prepayment: 'Thanh toán trước',
  pay_after_receive: 'Thanh toán sau khi nhận hàng',
  pay_on_duration: 'Thanh toán theo thời hạn',
  paymentTerm: 'Chính sách thanh toán',
  paymentDuration: 'Thời hạn thanh toán',
  markAsPaid: 'Đã thanh toán',
  referenceDocument: 'Chứng từ',
  actualAmount: 'Số tiền thực tế',
  massiveCreateSubsidy: 'Tạo hàng loạt tài trợ giá',

  deliveryConfiguration: 'Cấu hình giao hàng',
  deliveryMethod: 'Phương thức giao hàng',
  separateFulfillmentOrder: 'Tách đơn giao hàng',
  addLimit: 'Thêm',
  agentPhone: 'Số điện thoại NV bán hàng',
  newAgentName: 'Tên nhân viên bán hàng',
  allocatedWarehouse: 'Kho được phân bố',
  codeNumber: 'Mã',
  other: 'Khác',
  preOrderNumber: 'Mã đơn đặt trước',
  outletPhone: 'Số điện thoại cửa hàng',
  fullDelivery: 'Giao toàn bộ',
  partialDelivery: 'Giao một phần',
  cancelFilter: 'Quay lại',
  confirmFilter: 'Xác nhận',
  today: 'Hôm nay',
  this_week: 'Tuần này',
  this_month: 'Tháng này',
  this_quarter: 'Quý này',
  this_year: 'Năm này',
  outletDetail: 'Người nhận',
  agentInformation: 'Thông tin NV bán hàng',
  noOfSKU: 'Số lượng',
  grandTotal: 'Tổng tiền đơn hàng',
  totalPaid: 'Tổng tiền đã trả',
  totalRefunded: 'Tổng giá trị hoàn',
  totalDue: 'Tổng thanh toán còn lại',
  itemsOrdered: 'Sản phẩm đơn hàng',
  outletInformation: 'Thông tin người nhận',
  lat: 'Vị trí vĩ độ',
  long: 'Vị trí kinh độ',
  agent: 'Nhân viên bán hàng',
  agentCode: 'Mã số nhân viên',
  loginAccountType: 'Loại tài khoản',
  deviceID: 'Mã thiết bị',
  platform: 'Nền tảng',
  placeFromIP: 'Được đặt hàng từ IP',
  latitudeTakingOrder: 'Vị trí vĩ độ',
  longitudeTakingOrder: 'Vị trí kinh độ',
  orderInformation: 'Thông tin đơn hàng',
  paymentShippingMethod: 'Phương thức thanh toán - giao hàng',
  payment: 'Thanh toán',
  shippingHandling: 'Vận chuyển & Xử lý',
  grossAmount: 'Tạm tính',
  discount: 'Giảm giá',
  totalRefund: 'Total Refund',
  qtyOrdered: 'Trên đơn',
  originPrice: 'Giá gốc trên 1 số lượng',
  promotionDiscountAmount: 'Tổng khuyến mãi',
  subsidyDiscountAmount: 'Trợ giá nhà phân phối',
  saSubsidy: 'Trợ giá NV bán hàng',
  pricePerQty: 'Giá sau khuyến mãi & sau trợ giá ( trên 1 số lượng )',
  itemOrderAmountOrdered: 'Thành tiền sản phẩm',
  itemOrderAmountDelivered: 'Thành tiền sản phẩm (Đã giao)',
  grossSACommissions: 'Tổng Hoa hồng NV bán hàng',

  saCommissionAmount: 'Hoa hồng NV bán hàng',
  receiverDetail: 'Chi tiết người nhận',
  invoiceStatus: 'Trạng thái hóa đơn',
  expand: 'Mở rộng',
  collapse: 'Thu gọn',
  totalReturnedGoodsValue: 'Tổng giá trị hàng trả về',
  qtyDelivered: 'Đã giao',
  qtyReturned: 'Trả về',
  promotionProgram: 'Loại hoa hồng nhân viên bán hàng',
  saCommissionName: 'Trợ giá nhà phân phối',
  distributorSubsidies: 'Chương trình khuyến mãi',
  manualSubsidy: 'Trợ giá thủ công',
  autoSubsidy: 'Trợ giá tự động',
  specialSubsidy: 'Trợ giá đặc biệt',
  send: 'Gửi',
  originalSaCommission: 'Hoa hồng gốc nhân viên bán hàng',
  agentPriceSubsidy: 'Nhân viên bán hàng hỗ trợ',
  returnSaCommissionAmount: ' Hoa hồng hoàn lại',
  deliverySchedule: 'Lịch trình giao hàng',
  deliveryDay: 'Ngày giao hàng',
  maximumTime: 'Thời gian giao hàng tối đa',
  hour: 'Giờ',
  saPriceSupport: 'Nhân viên bán hàng hỗ trợ',
  returnSaCommission: ' Hoa hồng hoàn lại',
  saCommissionAmountTitle: 'Tổng hoa hồng nhân viên bán hàng',
  new: 'Mới',
  subtotal: 'Tạm tính',
  welcomeToVigo: 'Chào mừng đến với Quản lý Phân phối VIGO',
  descWelcome:
    'Đăng nhập và bắt đầu quản lý hiệu quả hoạt động kinh doanh phân phối của bạn',
  doNotAcc: `Chưa có tài khoản?`,
  signUp: 'Đăng ký',
  distributorRegisterDetail: 'Chi tiết đăng ký của nhà phân phối',
  approve: 'Duyệt',
  area: 'khu vực',
  noteTradeOrder: 'Ghi chú cho đơn hàng',

  deliveredQuantity: 'Đã giao',
  distributorPriceSubsidy: 'Trợ giá NPP',
  orderedQuantity: 'Trên đơn',
  originalPrice: 'Giá gốc',
  returnedQuantity: 'Trả về',
  salesCommissionAmount: 'Hoa hồng gốc',
  delivered: 'Giao hết',
  partial_delivered: 'Giao một phần',
  statusType: 'Loại Trạng thái',
  adjustedGrandTotal: 'Tổng tiền đơn hàng đã điều chỉnh',
  receiverType: 'Phân khúc người nhận',
  typeOfBusiness: 'Loại hình kinh doanh',
  messageSelectSku: 'Bấm để thêm <link>một hoặc nhiều sản phẩm</link>',
  updatedStockListing: 'Tồn kho được cập nhật',
  previousStockListing: 'Tồn kho cũ',
  confirmedQuantity: 'Số lượng đã xác nhận',
  dynamicSAcommissions: 'Tổng giá trị động hoa hồng NV bán',
  itemOrderAmount: 'Giá trị sản phẩm',
  dynamicCommissionAmount: 'Hoa hồng tự chọn',
  net: 'Thực tế',
  qtyConfirmed: 'Đã xác nhận',
  budgetType: 'Loại ngân sách',
  productAvailableOnTheMarket: 'Sản phẩm có sẵn trên thị trường',
  productOnMarket: 'Sản phẩm trên Thị trường',
  productRequested: 'Sản phẩm được yêu cầu',
  salePriceRange: 'Khoảng giá bán',
  purchasePriceRange: 'Khoảng giá mua',
  from: 'Từ',
  to: 'Đến',
  skuCode: 'Mã SKU',
  size: 'K.Thước',
  productSKU: 'Sản phẩm - SKU',
  packageSize: 'Quy cách đóng gói',
  weight: 'Cân nặng',
  salePrice: 'Giá bán',
  purchasePrice: 'Giá nhập',
  otherProductInformation: 'Thông tin khác của sản phẩm',
  countryCode: 'Mã quốc gia',
  description: 'Mô tả',
  alternativeName: 'Tên thay thế',
  characteristics: 'Đặc tính',
  length: 'Chiều Dài',
  width: 'Chiều Rộng',
  height: 'Chiều Cao',
  lengthLabel: 'Dài',
  widthLabel: 'Rộng',
  heightLabel: 'Cao',
  requestCreateNewProduct: 'Yêu cầu thêm sản phẩm mới',
  warningCreateProduct:
    'Vui lòng điền đầy đủ thông tin và bấm Xác nhận để yêu cầu thêm mới sản phẩm.',
  packageUnit: 'Quy cách đóng gói',
  productImage: 'Hình ảnh sản phẩm',
  productLink: 'Sản phẩm liên kết',
  lengthRange: 'Phạm vi chiều dài',
  widthRange: 'Phạm vi chiều rộng',
  heightRange: 'Phạm vi chiều cao',
  weightRange: 'Phạm vi cân nặng',
  effectiveFrom: 'Effective From',
  effectiveTo: 'Effective To',
  cm3Target: 'CM3 Mục tiêu',
  requestLevel2Approve: 'Yêu cầu duyệt cấp 2',
  requestUser: 'Người yêu cầu',
  approveUser: 'Người duyệt',
  level2Approve: 'Duyệt cấp 2',
  relatedDocuments: 'Thông tin liên quan',
  requestDate: 'Ngày yêu cầu',
  warehouseProvince: 'Kho hàng theo tỉnh',
  addSupplier: 'Thêm nhà cung cấp',
  close: 'Đóng',
  packType: 'Đơn vị đóng gói',
  averagePriceLastMonth: 'Giá trung bình tháng trước',
  requestQuantity: 'Số lượng yêu cầu',
  quotationQuantity: 'Số lượng báo giá',
  discountPercent: 'Chiết khấu',
  beforeDiscount: 'Trước chiết khấu',
  termAndCondition: 'Điều khoản và điều kiện',
  currentStatus: 'Trạng thái hiện tại',
  creationTime: 'Thời gian tạo',
  purchaseRequestNumber: 'Mã yêu cầu mua hàng',
  viewNote: 'Xem ghi chú',
  viewTermsAndCondition: 'Xem Điều khoản và điều kiện',
  min: 'Tối thiểu',
  max: 'Tối đa',
  select: 'Chọn',
  deselect: 'Bỏ chọn',
  specialCommission: 'Hoa hồng đặc biệt',
  vigoCommission: 'Hoa hồng Vigo',
  allocatedShippingFee: 'Phí vận chuyển phân bổ',
  actualOfferPrice: 'Giá đề nghị thực tế',
  vigoOfferPrice: 'Giá đề nghị từ Vigo',
  sellingPrice: 'Giá bán',
  supplierCode: 'Mã nhà cung cấp',
  supplierName: 'Tên nhà cung cấp',
  shippingProvince: 'Tỉnh vận chuyển',
  supplierDelivery: 'Nhà cung cấp giao hàng',
  overallProcess: 'Quy trình tổng thể',
  clickView: 'Bấm để xem',

  ward: 'Phường',
  limitRegionSupport: 'Giới hạn khu vực hỗ trợ giao hàng',
  packageSizeRequest: 'Kích thước đóng gói',
  packageTypeRequest: 'Loại đóng gói',
  date: 'Thời gian',
  contentEmptyDataSupplier:
    'Không tìm thấy Nhà cung cấp <link>có mã số thuế tương ứng.</link>',
  createNow: 'Tạo ngay',
  invoiceFile: 'Tệp tin hoá đơn',
  supplierSupportDeliveryFee: 'Nhà cung cấp hỗ trợ phí giao',
  estimateDeliveryDay: 'Ngày giao ước tính',
  credit: 'Ghi nợ',
  debtAmt: 'Số tiền nợ',
  numberDayDebt: 'Số ngày nợ',
  searchTaxCode: 'Tìm bằng mã số thuế',
  supplierInfo: 'Thông tin nhà cung cấp',
  contractNumber: 'SĐT liên hệ',
  addressOnInvoice: 'Địa chỉ trên hoá đơn',
  taxNumber: 'Mã số thuế',
  bankAccName: 'Ngân hàng',
  bankAccNumber: 'Số tài khoản',
  accName: 'Chủ tài khoản',
  errorTaxCode: 'Mã số thuế đã tồn tại!',
  cancelSupplier: 'Hủy tạo nhà cung cấp',
  placeholderSelectCategory: 'Vui lòng chọn một hoặc nhiều danh mục',
  selectPR: 'Chọn yêu cầu mua hàng',
  messageSelectPR:
    'Bấm để thêm <link>một hoặc nhiều yêu cầu mua hàng</link> để tạo',
  addPR: 'Thêm yêu cầu mua hàng',
  viewEdit: 'Xem/Sửa báo giá',
  supplierDeliveryFee: 'Phí giao nhà cung cấp',
  confirmAndNext: 'Xác nhận & Tiếp tục',
  ratioAccept: 'Tỷ lệ chấp nhận',
  creditInfo: 'Thông tin nợ',
  disagree: 'Không đồng ý',
  agreeAndConfirm: 'Đồng ý và xác nhận',
  deliveryTo: 'Giao đến',
  deliveryInformation: 'Thông tin giao hàng',
  purchasedByFrom: 'Người mua (Từ)',
  supplierTo: 'Nhà cung cấp (Đến)',
  relatedDocumentsPO: 'Tài liệu liên quan',
  purchaseDetails: 'Chi tiết mua hàng',
  shipment: 'Chuyến hàng',
  noOfItem: 'Số lượng giao hàng',
  debtAmount: 'Số lượng hàng',
  deliveredBy: 'Tự giao bởi',
  creditInformation: 'Thông tin nợ',
  vigoPO: 'Mã đơn mua hàng Vigo',
  distributorPO: 'Mã đơn mua hàng Nhà phân phối',
  deliveryBy: 'Giao bởi ',
  clickHereToView: 'Nhấn đây để xem',
  deliveryFrom: 'Giao từ',
  deliveryCode: 'Mã đơn giao hàng',
  printDeliveryOrInbound: 'In phiếu giao/nhập hàng',
  inboundInformation: 'Thông tin nhập hàng',
  replacedBy: 'Thay thế bởi DPO',
  attachedFile: 'Tệp đính kèm',
  purchaseOrderNumber: 'Mã đơn mua',
  reasonReject: 'Lí do từ chối',
  dateInvoice: 'Hóa đơn ngày',
  invoiceMediaID: 'Mã tệp hóa đơn',
  paymentNote: 'Ghi chú thanh toán',
  confirmRecordDebt: 'Xác nhận công nợ',
  debtReminder: 'Ngày nhắc nợ',
  debtTotalAmount: 'Tổng số tiền nợ',
  debtDueDate: 'Hạn nợ',
  debtReminderDate: 'Ngày nhắc nợ',
  totalActualValue: 'Tiền đã thu',
  newPurchasePrice: 'Giá mua',
  newPurchasePriceVAT: 'Giá mua (VAT)',
  documents: 'Tài liệu',
  province: 'Tỉnh',
  estimateSaleAgentCommission: 'Hoa hồng nhân viên bán hàng ước tính',
  infoExport: 'Hệ thống sẽ mặc định xuất dữ liệu của 3 tháng gần nhất.',
  errorExport:
    'Hệ thống sẽ mặc định xuất dữ liệu trong 3 tháng gần nhất (khi người dùng không chọn bộ lọc ngày) và tối đa 6 tháng (khi người dùng chọn bộ lọc ngày).',
  saleSupervisor: 'Giám sát bán hàng',
  availability: 'Số lượng có sẵn',
  case: 'Thùng',
  pcs: 'Lẻ',
  allocateInventory: 'Tồn kho thực hiện phân bổ',
  inventoryAllocationManagement: 'Quản lý phân bổ hàng tồn kho',
  inventoryAllocationNumber: 'Mã phân bổ hàng tồn kho',
  sourceAllocate: 'Nguồn phân bổ',
  destinationAllocate: 'Điểm nhận phân bổ',
  createInventoryAllocation: 'Tạo phân bổ hàng tồn kho',
  warehouseAddress: 'Địa chỉ kho hàng',
  allocateBatchNumber: 'Số lô tồn kho thực hiện phân bổ',
  warehouseName: 'Tên kho hàng',
  saleSupervisorName: 'Tên giám sát bán hàng',
  saleSupervisorEmail: 'Email giám sát bán hàng',
  saleSupervisorCode: 'Mã giám sát bán hàng',
  inventoryAllocationDetails: 'Chi tiết phân bổ hàng tồn kho',
  inventoryAllocation: 'Phân bổ hàng tồn kho',
  link: 'Liên kết',
  unLink: 'Hủy liên kết',
  saleAgent: 'Nhân viên bán hàng',
  saleAgentName: 'Tên nhân viên bán hàng',
  saleAgentCode: 'Mã nhân viên bán hàng',
  placeholderOneOption: 'Vui lòng chọn một lựa chọn',
  quantityAllocateInventory: 'Số lượng phân bổ',
  hubWarehouse: 'Kho hàng tổng',
  firstName: 'Tên',
  lastName: 'Họ',
  outletCode: 'Mã cửa hàng',
  order: 'Đơn hàng',
  seeMore: 'Xem thêm',
  collectionAmount: 'Số tiền thu',
  errorSameDis: 'Kho nguồn và kho đích phải cùng Nhà Phân Phối',
  vatCOGSVigo: 'Giá vốn Vigo(VAT)',
  actualCOGSVigo: 'Giá vốn Vigo',
  VPOCode: 'Mã VPO',
  SKUCode: 'Mã hàng',
  unitV2: 'Đơn vị tính',
  purchaseQuantity: 'Số lượng nhập hàng',
  priceVAT: 'Giá VAT',
  totalPurchaseValue: 'Tổng thành tiền VAT',
  totalQuantitySold: 'Tổng số lượng bán',
  totalQuantitySoldWeek: 'Tuần {name} SL bán',
  salableStock: 'Tổng tồn kho ',
  reservedStock: 'Số lượng tồn đã đặt hàng',
  productNameV2: 'Tên hàng hóa',
  VPOCreatedDate: 'Ngày tạo VPO',
  numberOfDay: 'Số ngày đối soát tiền hàng ký gửi',
  slaConfirm: 'Thời hạn xác nhận đối soát tiền hàng ký gửi',
  sellingQuantity: 'Số lượng bán',
  totalPriceVAT: 'Thành tiền sau thuế',
  totalVatPrice: 'Tổng tiền VAT',
  adjustmentReason: 'Lý do yêu cầu điều chỉnh',
  adjustmentSuggestion: 'Gợi ý điều chỉnh',
  mediaID: 'Tập tin',
  mediaName: 'Tên tập tin',
  pay_on_day: 'Thanh toán đúng ngày',
  paymentDueDate: 'Ngày tới hạn thanh toán',
  upcomingPaymentDueDate: 'Hạn thanh toán sắp tới',
  paymentReminderDate: 'Ngày nhắc nhở thanh toán',
  principleBrandGroup: 'Nhóm thương hiệu độc quyền',
  principleLocation: 'Khu vực độc quyền',
  requestConfirm: 'Yêu cầu xác nhận',
  vigo_ws_warehouse: 'Kho Sỉ Vigo',
  dis_ws_warehouse: 'Kho Sỉ NPP',
  distributor_brand_good: 'Nhãn Chuyên Trách NPP',
  normal_pr: 'Cơ Bản',
  principal: 'Độc Quyền',
  purchaseRequestType: 'Loại yêu cầu mua hàng',
  supplierSource: 'Nguồn cung cấp',
  debt: 'Công nợ',
  debtLimit: 'Hạn mức công nợ',
  debtAvailable: 'Công nợ khả dụng',
  debtUtilize: 'Công nợ đã dùng',
  addPhase: 'Thêm giai đoạn',
  paymentPhases: 'Giai đoạn thanh toán',
  phase: 'Giai đoạn',
  paymentMethodSelection: 'Chọn phương thức thanh toán',
  addMorePhase: 'Thêm giai đoạn',
  paymentPhaseOfDistributor: 'Giai đoạn thanh toán nhà phân phối',
  seeMorePhase: 'Xem thêm {phases} giai đoạn',

  //Date
  [WORKING_DAY_MONDAY]: 'Thứ hai',
  [WORKING_DAY_TUESDAY]: 'Thứ ba',
  [WORKING_DAY_WEDNESDAY]: 'Thứ tư',
  [WORKING_DAY_THURSDAY]: 'Thứ năm',
  [WORKING_DAY_FRIDAY]: 'Thứ sáu',
  [WORKING_DAY_SATURDAY]: 'Thứ bảy',
  [WORKING_DAY_SUNDAY]: 'Chủ nhật',
  //menu
  [NOTIFICATION]: 'Thông báo',
  [SUBMIT_BIDDING]: 'Nộp đấu thầu',
  [PURCHASE_REQUEST]: 'Yêu cầu mua hàng',
  [VIGO_QUOTATION]: 'Báo giá từ Vigo',
  [DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB]: 'Đơn mua hàng',
  [PAYMENT_REQUEST_BREADCRUMB]: 'Yêu cầu thanh toán',
  [MASTER_DATA]: 'Dữ liệu tổng',
  [MENU_PRODUCT]: 'Sản phẩm',
  [PRODUCT_AVAILABLE]: 'Sản phẩm trên thị trường',
  //Partner
  [PARTNER]: 'Đối tác',
  //Agent
  [AGENT]: 'Nhân viên bán hàng',
  [AGENT_MANAGEMENT]: 'Quản lý nhân viên bán hàng',
  [AGENT_COLLECTION]: 'Thu tiền nhân viên bán hàng',
  // Distributor profile
  [DISTRIBUTOR_MANAGEMENT]: 'Quản lý nhà phân phối',
  [DISTRIBUTOR_REGISTER]: 'Đăng ký nhà phân phối',
  [DISTRIBUTOR_PROFILE]: 'Hồ sơ nhà phân phối',
  [PRODUCT_LIST_VN]: 'Sản phẩm',
  [SUPPLIER_PRODUCT_LIST_VN]: 'Sản phẩm của nhà cung cấp',
  [REASON_NEED_ADJUST]: 'Lý do điều chỉnh',
  [INVENTORY_RECOMMEND]: 'Thiết lập nguồn hàng đề nghị',
  [DISTRIBUTOR_LIST]: 'Nhà phân phối',
  [DISTRIBUTOR_MARGIN]: 'Distributor Margin',
  [PRODUCT_LIST]: 'Sản phẩm',
  [PRODUCT_LISTING]: 'Sản phẩm niêm yết',
  [SUPPLIER_LIST_VN]: 'Nhà cung cấp',
  [SUPPLIER_LIST]: 'Nhà cung cấp',
  [SUPPLIER_PRODUCT_LIST]: 'Sản phẩm của nhà cung cấp',
  [WAREHOUSE_LIST]: 'Kho hàng',
  [PRODUCT_PRE_ORDER]: 'Sản phẩm đặt trước',
  [PRODUCT_PRE_ORDER_INDIA]: 'Sản phẩm đặt trước',
  [PRODUCT_TRADING_BY_LISTING]: 'Sản phẩm niêm yết trên giao dịch',
  //Menu Supplier Portal
  [SUPPLIER_PORTAL]: 'Trung tâm cung cấp hàng',
  //Children Supplier  Portal
  [PURCHASE_ORDER_LIST]: 'Đơn đặt hàng',
  [BIDDING_REQUEST]: 'Yêu cầu đấu thầu',
  [BIDDING_INVITATION_LIST]: 'Lời mời đấu thầu',
  [TOTAL_INVENTORY_REPORT]: 'Báo cáo tồn kho',

  //Menu Brand Portal
  [BRAND_PORTAL]: 'Thương hiệu',
  [SALES_PERFORMANCE]: 'Báo cáo hiệu suất bán hàng',
  [CATEGORY_INSIGHTS]: 'Báo cáo chi tiết danh mục',
  [DELIVERY_PERFORMANCE]: 'Báo cáo hiệu suất giao hàng',
  [INVENTORY_REPORT]: 'Báo cáo tồn kho',
  [AGENTS_PERFORMANCE]: 'Báo cáo hiệu suất nhân viên bán hàng',
  [OUTLETS_PERFORMANCE]: 'Báo cáo hiệu suất cửa hàng',
  [COVERAGE_REPORT]: 'Báo cáo mức độ bao phủ',
  [PRICE_MANAGEMENT]: 'Price Management Report',
  [MISSION_PERFORMANCE]: 'Mission Performance Report',

  //Distributor Center
  [SA_PAYOUT_STATEMENT_DETAIl]: 'SA Payout Statement Detail',
  [DISTRIBUTOR_CENTER]: 'Trung tâm phân phối',
  [PURCHASING]: 'Thu mua',
  [DISTRIBUTOR_PURCHASE_REQUEST]: 'Yêu cầu mua hàng của nhà phân phối',
  [CONSOLIDATED_PURCHASE_REQUEST]: 'Tổng hợp yêu cầu mua hàng',
  [VIGO_MASTER_QUOTATION]: 'Giá tổng của Vigo',
  [DISTRIBUTOR_PURCHASE_ORDER]: 'Đơn đặt hàng của nhà phân phối',
  [VIGO_PURCHASE_ORDER]: 'Đơn đặt hàng của Vigo',
  [LOGISTICS]: 'Quản lý giao vận mua hàng',
  [TRANSPORTATION_VENDOR]: 'Nhà cung cấp dịch vụ vận chuyển',
  [PROCUREMENT]: 'Quản lí mua hàng',
  [AUTOMATIC_PROCUREMENT_CONFIG]: 'Cấu hình tự động mua hàng',
  [REASON_CANCEL]: 'Lý do hủy',
  [VIGO_DELIVERY]: 'Giao hàng bởi Vigo',
  [GOODS_NOT_FOCUS]: 'Hàng không tập trung',
  [CONFIG_CM3_TARGET]: 'Cài đặt CM3 Mục Tiêu',
  [DEPOSIT_CONFIG]: 'Cấu hình ký quỹ',
  //WMS
  [WMS]: 'Quản lý kho hàng',
  [WAREHOUSE_WMS_LIST]: 'Kho hàng',
  [STOCK_TRANSFER]: 'Luân chuyển tồn kho',
  [INBOUND_LIST]: 'Nhập hàng',
  [INVENTORY_DISTRIBUTOR_AGENT]: 'Tồn kho nhân viên bán hàng của nhà phân phối',
  [INVENTORY_LIST]: 'Tồn kho',
  [INVENTORY_ALLOCATION]: 'Phân bổ hàng tồn kho',
  [AUTO_INVENTORY_REPLENISHMENT]: 'Tự động bổ sung hàng tồn kho',
  [FULFILLMENTS]: 'Bán hàng',
  [ORDER_LIST]: 'Đơn giao hàng',
  [PRE_ORDERS]: 'Đơn đặt trước',
  [TRADE_ORDERS]: 'Đơn hàng',
  [PICKLIST_ASSIGNMENT]: 'Quản lý lấy hàng và đóng gói',
  [FINANCE]: 'Tài chính',
  [PRICE_TYPE_CONFIG]: 'Loại giá tính hoa hồng Vigo',
  [BUY_IN_INVOICE]: 'Hóa đơn mua vào',
  [TAX_MANAGEMENT]: 'Quản lý thuế VAT',
  [DISTRIBUTOR_CONTRACT_FEE]: 'Phí Hợp Đồng Nhà Phân Phối',
  [SUPPLIER_INVOICE_PRODUCT_MANAGEMENT]: 'Liên kết hoá đơn nhà cung cấp',
  [SELL_OUT_INVOICE]: 'Hóa đơn bán ra',
  [PAYOUT_STATEMENT]: 'Sao kê thanh toán cho nhân viên bán hàng',
  [FINANCE_TASK_LIST]: 'Thao tác hàng loạt cho thanh toán',
  [PAYMENT_REQUEST]: 'Yêu cầu thanh toán',
  [CLAIMS_MANAGEMENT]: 'Quản lý yêu cầu hoàn chi phí',
  [RIDER]: 'Quản lý giao hàng',
  [RIDER_MANAGEMENT]: 'Quản lý tài xế',
  [VEHICLE_MANAGEMENT]: 'Quản lý phương tiện',
  [DELIVERY_MANAGEMENT]: 'Quản lý tuyến giao hàng',
  [FAILED_DELIVERY_RECEIVE]: 'Phân lại tuyến giao hàng',
  [PACKAGE_LIST]: 'Kiện hàng',
  [REMITTANCE_LIST]: 'Thu tiền',
  [REPORT]: 'Báo cáo',
  [REPORT_RIDER]: 'Tài xế',
  [REPORT_DISTRIBUTOR_RECONCILIATION]: 'Đối soát nhà phân phối',
  [REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY]:
    'Bản tóm tắt đối soát nhà phân phối',
  [MARKETING]: 'Tiếp thị',
  [MARKETING_BUDGET]: 'Ngân sách',

  [SUBSIDY_RULE]: 'Trợ giá',
  [AGENT_INCENTIVES]: 'Nhiệm vụ cho nhân viên bán hàng',
  [AGENT_GROUP]: 'Nhóm nhân viên bán hàng',
  [INCENTIVES_CONFIG]: 'Cấu hình nhiệm vụ',
  [SETTINGS]: 'Cài đặt',
  [USER_LIST]: 'Người dùng',
  [TASK_LIST]: 'Thao tác hàng loạt',
  [PROCUREMENT_CONFIG]: 'Mua hàng',
  [AUTOMATIC_PARTIAL_FULFILLMENT]: 'Tỷ trọng tự động phối hàng',
  [TOTAL_PURCHASE_VALUE_REPORT]: 'Báo cáo tổng giá trị mua vào',
  [CONSIGNMENT_CASH_RECONCILIATION]: 'Đối soát tiền hàng ký gửi',
}

export const vi = {
  [HOME]: HOME_VI,
  [COMMON]: common,
  [PURCHASING]: PURCHASING_VI,
  [PURCHASE_REQUEST]: PURCHASE_REQUEST_VI,
  [VIGO_QUOTATION]: VIGO_QUOTATION_VI,
  [DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB]: DISTRIBUTOR_PURCHASE_ORDER_VI,
  [PAYMENT_REQUEST_BREADCRUMB]: PAYMENT_REQUEST_VI,
  [TASK_LIST]: TASK_VI,
  [PRODUCT_PRE_ORDER]: PRODUCT_PRE_ORDER_VI,
  [PRODUCT_LISTING]: PRODUCT_LISTINGS_VI,
  [PRODUCT_TRADING_BY_LISTING]: PRODUCT_TRADING_BY_LISTING_VI,
  [INBOUND]: INBOUND_VI,
  [INVENTORY]: INVENTORY_VI,
  [REASON_NEED_ADJUST]: REASON_NEED_ADJUST_VI,
  [ORDERS]: ORDERS_VI,
  [PRE_ORDERS_TRANSLATE]: PRE_ORDERS_VI,
  [TRADE_ORDERS_TRANSLATE]: TRADE_ORDERS_VI,
  [PICKLIST]: PICKLIST_VI,
  [AUTO_INVENTORY_REPLENISHMENT]: AUTO_INVENTORY_REPLENISHMENT_VI,

  [RIDERS]: RIDER_VI,
  [VEHICLE]: VEHICLE_VI,
  [DELIVERY]: DELIVERY_VI,
  [PACKAGE]: PACKAGE_VI,
  [REMITTANCE]: REMITTANCE_VI,
  [RIDER_REPORT]: REPORT_RIDER_VI,
  [BUY_IN_INVOICE_KEY]: BUY_IN_INVOICE_VI,
  [SELL_OUT_INVOICE_KEY]: SELL_OUT_INVOICE_VI,
  [SUBSIDY]: SUBSIDY_VI,
  [PO]: PO_VI,
  [BIDDING_REQUEST_KEY]: BIDDING_REQUEST_VI,
  [BIDDING_INVITATION_KEY]: BIDDING_INVITATION_VI,
  [WAREHOUSE]: WAREHOUSE_VI,
  [BUDGET]: BUDGET_VI,
  [DISTRIBUTOR_PROFILE_MANAGEMENT]: DISTRIBUTOR_PROFILE_VI,
  [AUTOMATIC_PROCUREMENT_CONFIG]: AUTOMATIC_CONFIG_VI,
  [STOCK_TRANSFER]: STOCK_TRANSFER_VI,
  [SIGN_UP]: SIGN_UP_VI,
  [PRODUCT]: PRODUCT_VI,
  [CONTRACT_FEE]: DISTRIBUTOR_CONTRACT_FEE_VI,
  [CONSOLIDATED_PR]: CONSOLIDATED_PR_VI,
  [VIGO_MASTER]: VIGO_MASTER_QUOTATION_VI,
  [VIGO_PO]: VIGO_PURCHASE_ORDER_VI,
  [AGENT_TRANSLATE]: AGENT_VI,
  [AGENT_COLLECTION_TRANSLATE]: AGENT_COLLECTION_VI,
  [PO_REPORT]: PURCHASE_ORDER_REPORT_VI,
  [DEPOSIT]: DEPOSIT_CONFIG_VI,
  [CONSIGNMENT_CASH]: CONSIGNMENT_CASH_VI,
}
